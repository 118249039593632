import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';




// About.js

const About = () => {
  return <h1>About Page</h1>;
};


// Contact.js

const Contact = () => {
  return <h1>Contact Page</h1>;
};


function App() {
  return (
    <div>
     
     <Router>
      <div className="App">
        <Dashboard
         />
      </div>
    </Router>

    </div>
  );
}

export default App;
