// src/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import {
    MenuOutlined,
    UserOutlined,
    FontSizeOutlined,
    NotificationOutlined,
    FontColorsOutlined,
    StrikethroughOutlined
} from '@ant-design/icons';
import { Link, Route, Routes } from 'react-router-dom';
import './Dashboard.css';
import XResponse from './Container/XResponse';
const { Header, Sider, Content } = Layout;
const headerName = {
    Amadeus: 'Amadeus Fare Master Pricer Travel Board Search MPTB Visualizer',
    Sabre: 'Sabre Bargain Finder Max Visualizer',
    Travelport: 'Travelport Low Fare Search Visualizer'
}

const SabreData =()=>{
    return(
        <h2>Sabre - Coming Soon ...</h2>
    )
}

const TravelPort =()=>{
    return(
        <h2>TravelPort - Coming Soon ...</h2>
    )
}

const Dashboard = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [stateName, setStateName] = useState('Amadeus')

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
                setDrawerVisible(false); // Close drawer on switching to desktop view
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const menu = (
        <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            style={{ height: '100%', backgroundColor: isMobile ? '#1890ff' : '#fff', color: isMobile ? '#fff' : 'inherit' }}
            className="custom-menu"
            onClick={isMobile ? closeDrawer : null}
        >

            <Menu.Item key="1" icon={<FontColorsOutlined />} onClick={() => setStateName('Amadeus')} style={{ color: isMobile ? '#fff' : 'inherit' }}>
                <Link to="/" style={{ color: isMobile ? '#fff' : 'inherit' }} >Amadeus</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<StrikethroughOutlined />} onClick={() => setStateName('Sabre')} style={{ color: isMobile ? '#fff' : 'inherit' }}>
                <Link to="/sabre" style={{ color: isMobile ? '#fff' : 'inherit' }}>Sabre</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<FontSizeOutlined />} onClick={() => setStateName('Travelport')} style={{ color: isMobile ? '#fff' : 'inherit' }}>
                <Link to="/travelport" style={{ color: isMobile ? '#fff' : 'inherit' }}>Travelport</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<NotificationOutlined />} onClick={() => setStateName('DummyItineraryForVisa')} style={{ color: isMobile ? '#fff' : 'inherit' }}>
                <Link to="/notification" style={{ color: isMobile ? '#fff' : 'inherit' }}>DummyItineraryForVisa</Link>
            </Menu.Item>

        </Menu>
    );

    

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{
                background: '#ffff', padding: '0 10px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 1px 2px 0 rgba(0,0,0,.03),0 1px 6px -1px rgba(0,0,0,.02),0 2px 4px 0 rgba(0,0,0,.02)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
            }}>
                <h2 style={{ margin: 0, color: 'rgba(0, 0, 0, 0.88)' }}>
                    {headerName[stateName]}
                </h2>
                {isMobile && <Button className="menu-button" icon={<MenuOutlined />} onClick={showDrawer} />}
                <Drawer
                    title="Menu"
                    placement="right"
                    onClose={closeDrawer}
                    visible={drawerVisible}
                    bodyStyle={{ padding: 0 }}
                    headerStyle={{ background: '#1890ff', color: '#fff' }}
                >
                    {menu}
                </Drawer>
            </Header>
            <Layout>
                {isMobile ? null : (
                    <Sider
                        breakpoint="lg"
                        collapsedWidth="0"
                        style={{ background: '#fff', borderRight: '1px solid #e8e8e8' }}
                        className="sider-menu"
                    >
                        {menu}
                    </Sider>
                )}
                <Layout>
                    <Content style={{ margin: '10px', padding: '10px', background: '#fff' }}>
                        <Routes>
                            <Route path="/" element={<XResponse />} />
                            <Route path="/sabre" element={<SabreData />} />
                            <Route path="/travelport" element={<TravelPort />} />
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default Dashboard;
