import { useState } from "react";
import { Button, Form, } from "react-bootstrap";

import Recommendations from "../Components/Recommendations";
import { Menu, Table } from "antd";

const XResponse = () => {
    const [response, setResponse] = useState('');
    const [chosenRecommendation, setChosenRecommendation] = useState(null);
    const [showRecommendations, setShowRecommendations] = useState(false);

    const handleChoose = (rec) => {
        setChosenRecommendation(rec);
        setShowRecommendations(false);
    };

    const handleCancel = () => {
        setShowRecommendations(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowRecommendations(true);

    };

    const handleClear = () => {
        setResponse('')
    }
    const columns = [
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price'
        },
        {
          title: 'Segments',
          dataIndex: 'segments',
          key: 'segments',
       
        }
      ];
    return (
        <div>
            <div className="text-area-form ">
                <Form className="header-form" onSubmit={handleSubmit}>
                    <Form.Group controlId="formXmlResponse">
                        <Form.Control
                            as="textarea"
                            rows={15}
                            placeholder="Paste Amadeus MPTB response here.."
                            value={response}
                            onChange={(e) => { setResponse(e.target.value); setShowRecommendations(false) }}
                        />
                    </Form.Group>
                    <div className="submit-btn">
                        <Button variant="danger" onClick={handleClear} >Clear</Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>

            {showRecommendations && response ? (
                <Recommendations
                    response={response}
                    onChoose={handleChoose}
                    onCancel={handleCancel}
                />
            ) : <>
             <h5>Flight Details</h5>
                  <Table columns={columns} dataSource={[]}  />
            </>} 
        </div>
    );
};

export default XResponse;