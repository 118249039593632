
import React, { useState } from 'react';
import FlightDetailsTable from './FlightDetailsTable';


const parseRecommendations = (response) => {
    const parser = new DOMParser();
    const slr = parser.parseFromString(response, 'text/xml');
    const recs = [];

    slr.querySelectorAll('recommendation').forEach((rec) => {
        const price = rec.querySelector('recPriceInfo monetaryDetail amount').textContent;
        const itemNumber = rec.querySelector('itemNumber').textContent;
        console.log("price",price, itemNumber)
        let recString = `<html><b>Price: ${price}</b>`;
        const reco = { price, itemNumber: parseInt(itemNumber) , segments: [] };

        rec.querySelectorAll('segmentFlightRef').forEach((flightSeg, segmentIdx) => {
            const flightIndexes = Array.from(flightSeg.querySelectorAll('referencingDetail'))
                .filter((ref) => ref.querySelector('refQualifier').textContent === 'S')
                .map((ref) => parseInt(ref.querySelector('refNumber').textContent, 10));
            const segs = [];
            flightIndexes.forEach((val, idx) => {
                const legs = [];
                recString += `<br><b>segment ${idx + 1}</b>: `;

                Array.from(slr.querySelectorAll('flightIndex')[idx].querySelectorAll('groupOfFlights')[val - 1].querySelectorAll('flightDetails'))
                    .forEach((flight, legIdx) => {

                        const flightNumber = flight.querySelector('flightInformation flightOrtrainNumber').textContent;
                        const locations = flight.querySelectorAll('flightInformation location');
                        const boardAirport = locations[0].querySelector('locationId')?.textContent;
                        const offAirport = locations[1].querySelector('locationId')?.textContent;
                        const depDate = flight.querySelector('flightInformation productDateTime dateOfDeparture')?.textContent;
                        const depTime = flight.querySelector('flightInformation productDateTime timeOfDeparture')?.textContent;
                        const marketCompany = flight.querySelector('flightInformation companyId marketingCarrier')?.textContent;
                        const operCompany = marketCompany; // Assuming operCompany is the same as marketCompany

                        const fareDetails = rec.querySelectorAll('paxFareProduct fareDetails');
                        let bookClass, fareBasis;
                        fareDetails.forEach((fareDetail) => {
                            const segmentRef = fareDetail.querySelector('segmentRef segRef').textContent;
                            if (segmentRef == idx + 1) {
                                const groupOfFares = fareDetail.querySelectorAll('groupOfFares');
                                groupOfFares.forEach((groupOfFare, fareIdx) => {
                                    if (fareIdx == legIdx) {
                                        bookClass = groupOfFare.querySelector('productInformation cabinProduct rbd').textContent;
                                        fareBasis = groupOfFare.querySelector('productInformation fareProductDetail fareBasis').textContent;
                                    }
                                });
                            }
                        });               
                        legs.push({ fareBasis, boardAirport, offAirport, flightNumber, depDate, depTime, marketCompany, operCompany, bookClass });
                    });

                segs.push(legs);
            });
            reco.segments.push(segs);
        });
        recs.push({ ...reco,});
    });
    return { recs };
};

const Recommendations = ({ response }) => {
     const { recs } = parseRecommendations(response);
     console.log(" recs",recs)
    return (
        <FlightDetailsTable data={recs} />
    );
};
export default Recommendations