import React from 'react';
import { Table } from 'antd';
import moment from 'moment';


const segmentColumns = [
  {
    title: 'Recommendation',
    dataIndex: 'recommendation',
    key: 'recommendation',
    className: 'no-border-cell', // Add custom class to the cells
    render: text => <div >
      {text}
    </div>, // Render function can be customized as needed

  },

  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    className: 'no-border-cell', // Add custom class to the cells
    render: text => <div >
      {text}
    </div>, // Render function can be customized as needed

  },
  {
    title: 'Market Company',
    dataIndex: 'marketCompany',
    key: 'marketCompany'
  },
  {
    title: 'Flight Number',
    dataIndex: 'flightNumber',
    key: 'flightNumber'
  },
  {
    title: 'Booking Class',
    dataIndex: 'bookClass',
    key: 'bookClass'
  },
  {
    title: 'Board Airport',
    dataIndex: 'boardAirport',
    key: 'boardAirport'
  },
  {
    title: 'Off Airport',
    dataIndex: 'offAirport',
    key: 'offAirport'
  },
  {
    title: 'Departure Date',
    dataIndex: 'depDate',
    key: 'depDate',
    render: depDate => moment(depDate, 'DDMMYY').format('DD-MM-YYYY')

  },
  {
    title: 'Departure Time',
    dataIndex: 'depTime',
    key: 'depTime',
    render: depTime => moment(depTime, 'HHmm').format('HH:mm')

  },
  
  {
    title: 'Operating Company',
    dataIndex: 'operCompany',
    key: 'operCompany'
  },
  {
    title: 'Fare Basis',
    dataIndex: 'fareBasis',
    key: 'fareBasis'
  },
  
  
];


const FlightDetailsTable = ({ data }) => {
  // const transformedData = data.map(entry => {
  //   return entry.segments.map(segmentGroup => {
  //     return {
  //       price: entry.price,
  //       segments: segmentGroup  
  //     };
  //   }).flat();
  // }).flat();

  const transformedData = data.map(entry => {
    return entry.segments.map(segmentGroup => {
      return segmentGroup.map((segmentArray, groupIndex) => {
        // Check if it's the first segment array in segmentGroup
        if (groupIndex === 0 && segmentArray.length > 0) {
          // Add price to the first object in the segmentArray
          return segmentArray.map((segment, index) => {
            if (index === 0) {
              return {
                ...segment,
                price: entry.price,
                recommendation: entry.itemNumber
              };
            } else {
              return segment;
            }
          }).flat();
        } else {
          return segmentArray;
        }
      }).flat();
    }).flat();
  }).flat();
  
  console.log("transformedData",transformedData);
  return (
    <>
      <h4>Flight Details</h4>
      <Table columns={segmentColumns} dataSource={transformedData}  pagination={50} 
      bordered
      />
    </>
  );
};

export default FlightDetailsTable;
